import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div>
      <div className="background"></div>
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="message">
        <h1>Hi, I'm Allie.</h1>
        <h2>product designer @ SAP by day, design engineer @ mi casa by night</h2>
        <p>
          I'm working on something amazing using {' '}
          <a href="https://www.cursor.com/" target="_blank" rel="noopener noreferrer">
            Cursor Ai
          </a>, {' '}
          <a href="https://lottiefiles.com/" target="_blank" rel="noopener noreferrer">
            Lottie
          </a>
          , and {' '}
          <a href="https://lummi.ai/" target="_blank" rel="noopener noreferrer">
            Lummi
          </a>. Come back soon!
        </p>
      </div>
    </div>
  );
}

export default App;
